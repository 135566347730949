/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Initialise owl carousel
        function initCarousel(){
          $("#carousel-primary").owlCarousel({
            pagination : false,
            navigation : true,
            navigationText: [
              "<i class='fa fa-angle-left'></i>",
              "<i class='fa fa-angle-right'></i>"
            ],
            rewindSpeed: 600,
            slideSpeed : 600,
            paginationSpeed : 600,
            singleItem:true,
            stopOnHover: true
          });
        }
        initCarousel();
        var owl = $("#carousel-primary").data('owlCarousel');

        function refreshCarousel(){
          if(owl){
            owl.reinit({
              pagination : false,
              navigation : true,
              navigationText: [
                "<i class='fa fa-angle-left'></i>",
                "<i class='fa fa-angle-right'></i>"
              ],
              rewindSpeed: 600,
              slideSpeed : 600,
              autoPlay : 8000,
              paginationSpeed : 600,
              singleItem:true,
              stopOnHover: true
            });
          }
        }

        //Logic for info pane
        function getBaseHeight(){
        var p = $("#main-info");
        var height = p.height();
        var baseHeight = height + parseInt($(".info-pane-content").css( "padding-bottom" )) - 4;
        return baseHeight;
        }

        function setBaseDistance(baseDistance){
        $("#main-info-pane").css("bottom", -baseDistance+"px");
        }

        function resetBaseDistance(){
          var baseDistance = parseInt($("#main-info-pane").css( "bottom" ));
          if (baseDistance < 0){
          setBaseDistance(0);
          setBaseDistance(getBaseHeight());
          }
        }

        function toggleInfo(){
          var baseDistance = parseInt($("#main-info-pane").css( "bottom" ));
          if (baseDistance < 0){
            setBaseDistance(0);
          } else {
            setBaseDistance(getBaseHeight());
          }
          $( "#main-info" ).toggleClass("info-pane-invisible");
          $("#main-info-toggle").find('i').toggleClass('fa-angle-up').toggleClass('fa-angle-down');
        }

        function collapseInfo(){
          setBaseDistance(getBaseHeight());
          $( "#main-info" ).toggleClass("info-pane-invisible");
          $("#main-info-toggle").find('i').toggleClass('fa-angle-up').toggleClass('fa-angle-down');
        }


        // Set info toggles
        $( "#main-info-toggle" ).click(function() {
          toggleInfo();
        });


        // Window events

        $( window ).resize(function() {
          refreshCarousel();
          resetBaseDistance();
        });
        $( window ).bind( "orientationchange", function() {
          refreshCarousel();
          resetBaseDistance();
        });
        // Set window load events
        $( window ).ready(function() {
          var width = $( window ).width();
          var height = $( window ).height();
          if (width < 1024 || height < 600){
            collapseInfo();
          }
        });
        $( window ).load(function() {
          if(owl){
            owl.play();
          }
        });

        //Menu icon toggle
        $( "#menu-icon" ).click(function() {
          $("#nav-icon3").toggleClass('open');
          $(".navbar").toggleClass('high-vis');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //Initialise wow.js effects
        new WOW().init();

        //Correct active states for iDevices
        if(/iP(hone|ad)/.test(window.navigator.userAgent)) {
          var elements = document.querySelectorAll('a');
          var emptyFunction = function() {};
          for(var i = 0; i < elements.length; i++) {
            elements[i].addEventListener('touchstart', emptyFunction, false);
          }
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //Prevent bounce effect on touch enabled mobile browsers
        document.ontouchmove = function(event){
          event.preventDefault();
        };

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_post': {
      finalize: function() {
        // Javascript to be fired on single post (project) pages

        //Prevent bounce effect on touch enabled mobile browsers
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
